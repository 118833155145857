import React, { useEffect } from 'react';
import { AppRouter } from './AppRouter';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import '@/app/core/setup/iconfont-setup';
import { useAppStore, useStoreState } from '@tw/tw-runtime-react';
import { TwProvider } from '@tw/tw-components-rn';
import Toast, { ToastProvider } from 'react-native-toast-notifications';
import { Text } from 'react-native';
import { BfAppContext } from '@tw/tw-runtime';

SplashScreen.preventAutoHideAsync().then();

export default function App(props) {
  console.log('props', props);
  const token = getTokenFromHref();
  if (token) {
    BfAppContext.getInstance().getAuthStorageService().setToken(token);
  }
  const { initLoadFinished } = useAppInitLoad();

  if (!initLoadFinished) {
    return null;
  }
  return (
    <TwProvider>
      <ToastProvider offsetTop={-50} offsetBottom={50}>
        <AppRouter />
        <Toast ref={(ref) => (global['toast'] = ref)} offsetTop={-50} offsetBottom={50} />
      </ToastProvider>
    </TwProvider>
  );
}

function getTokenFromHref() {
  const result = /token=([a-zA-Z0-9-]+)/.exec(location.search);
  if (!result) {
    return undefined;
  }
  return result[1];
}

function useAppInitLoad() {
  const appStore = useAppStore();
  const initLoadFinished = useStoreState(appStore, (state) => state.initLoadFinished);

  const [fontsLoaded] = useFonts({
    iconfont: require('@/app/assets/fonts/iconfont.ttf')
  });
  useEffect(() => {
    appStore.initialize().then();
  }, [appStore]);
  useEffect(() => {
    if (fontsLoaded) {
      SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  return {
    initLoadFinished: initLoadFinished && fontsLoaded
  };
}
