import { FC } from 'react';
import { Text, View } from 'react-native';
import { priceSummaryStyles } from '@/sale/pages/order-info/viewparts/order-product-info/styles';

export const OrderPriceSummary: FC<OrderPriceSummaryProps> = (props) => {
  const { priceAfter, priceBefore } = props;
  return (
    <View style={priceSummaryStyles.container}>
      <View style={priceSummaryStyles.row}>
        <Text style={priceSummaryStyles.beforeText}>折前价：</Text>
        <Text style={priceSummaryStyles.beforeText}>{`${priceAfter ?? '--'}元`}</Text>
      </View>
      <View style={[priceSummaryStyles.row, priceSummaryStyles.afterContainer]}>
        <Text style={priceSummaryStyles.afterText}>合计：</Text>
        <Text style={priceSummaryStyles.afterText}>{`${priceBefore ?? '--'}元`}</Text>
      </View>
    </View>
  );
};

export interface OrderPriceSummaryProps {
  priceBefore?: number;
  priceAfter?: number;
}
