import { ScreenProp } from '@/app/navigation/types';
import { styles } from './styles';
import {
  TwButton,
  TwChip,
  TwListItem,
  TwLoadingBlock,
  TwPage,
  TwPageHeader,
  TwRadio,
  TwTypography
} from '@tw/tw-components-rn';
import { BfAppContext } from '@tw/tw-runtime';
import React, { useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { ROUTE_NAMES } from '@/app/navigation/route-names';
import { AccountSwitchPage } from '@/sale/stores/account-switch/account-switch-page';
import { useStoreState } from '@tw/tw-runtime-react';
import { ResOauthUserModel } from '@tw/lock-api-gen';

export const AuthSwitchAccount = ({ navigation, route }: ScreenProp) => {
  const AccountSwitchStore = AccountSwitchPage.getInstance();
  const [page] = useState(AccountSwitchStore);

  const routeService = BfAppContext.getInstance().getRouteService();

  const isLoading = useStoreState(page, (state) => state.isLoading);
  const authList = useStoreState(page, (state) => state.authList);

  const onBack = () => {
    BfAppContext.getInstance().getRouteService().back();
  };

  const onPressAddAccount = () => {
    routeService.push({
      path: ROUTE_NAMES.AuthLoginNew
    });
  };

  const handleOnChangeAccount = (data: ResOauthUserModel) => {
    page.quickSwitch(data);
  };

  if (isLoading) {
    return <TwLoadingBlock />;
  }

  return (
    <TwPage
      translucentStatusBar={true}
      footer={
        <TwPage.Footer>
          <TwButton
            variant={'solid'}
            onPress={() => {
              page.onConfirmSwitch();
            }}
          >
            确认切换
          </TwButton>
        </TwPage.Footer>
      }
    >
      <TwPageHeader showBack={true} onBack={onBack} />

      <View style={[styles.container]}>
        <View style={styles.logo}>
          <Text style={styles.logoText}>切换账号登录</Text>
        </View>

        <View style={styles.list}>
          {authList.map((item, index) => {
            return (
              <TwListItem
                key={index}
                prefix={
                  <TwRadio checked={item.isSelected} onChange={() => handleOnChangeAccount(item)}>
                    <TwTypography.Text size={'md'}>{item.mobile}</TwTypography.Text>
                  </TwRadio>
                }
                pressable={true}
                onPress={() => handleOnChangeAccount(item)}
                suffix={item.isCurrentUsing && <TwChip variant="capsule" text={'当前登录'} selected={true} />}
              />
            );
          })}
          <TwListItem
            prefix={
              <View>
                <AntDesign name="pluscircleo" size={20} color="#8C8E8F" />
              </View>
            }
            pressable={true}
            onPress={onPressAddAccount}
            title={'添加账号'}
          />
        </View>
      </View>
    </TwPage>
  );
};
