import { BfAppContext } from '@tw/tw-runtime';
import { FC, useMemo, useState } from 'react';
import { TwButton, TwCheckbox } from '@tw/tw-components-rn';
import { Text, TouchableOpacity, View, ViewProps } from 'react-native';
import { useAppStore, useStoreState } from '@tw/tw-runtime-react';

import { styles } from './styles';
import { TwBfForm } from '@tw/tw-platform-rn';
import { BfFormElement } from '@tw/tw-platform-react';
import { CaptchaUsageEnumValue, UserTypeEnumValue } from '@tw/lock-api-gen';
import { AccountSwitchPage } from '@/sale/stores/account-switch/account-switch-page';

export const LoginFormVerification: FC<ViewProps> = (props) => {
  const commonStore = AccountSwitchPage.getInstance();
  const [page] = useState(commonStore);

  const messageService = BfAppContext.getInstance().getMessageService();
  const routeService = BfAppContext.getInstance().getRouteService();
  const appStore = useAppStore();
  const [form] = useState(() => TwBfForm.createForm());
  const elements = useElements();
  const onLoginClick = async () => {
    const { phone, captcha } = await form.submit();
    if (!phone) {
      messageService.showError('请输入手机号');
      return;
    }
    if (!captcha) {
      messageService.showError('请输入验证码');
      return;
    }

    await messageService.showLoading();
    await page.quickBind(phone, captcha);
    await messageService.hideLoading();
    routeService.back();

    // store.setLoginMethod(BfLoginMethod.captcha);
    // store.updateLoginForm({
    //   phone: phone,
    //   captcha: captcha
    // });
    // await messageService.showLoading();
    // let exits = false;
    // try {
    //   exits = await store.checkUserNameExits();
    // } finally {
    //   await messageService.hideLoading();
    // }

    // if (!exits) {
    //   messageService.showError('手机号未注册');
    //   await messageService.hideLoading();
    //   return;
    // }
    // if (!store.isPolicyAgreed()) {
    //   const confirm = await messageService.showConfirm({
    //     title: '登录提示',
    //     content: '登录代表您同意使用协议'
    //   });
    //   if (!confirm) {
    //     await messageService.hideLoading();
    //     return;
    //   }
    //   store.setPolicyAgree(true);
    // }
    // await messageService.hideLoading();
    // await store.login();
  };
  // const onAgreeCheck = (checked: boolean) => {
  //   store.setPolicyAgree(checked);
  // };

  // const onPrivacyPolicyClick = () => {
  //   routeService.push({
  //     path: ROUTE_NAMES.HomePrivacyPolicy
  //   });
  // };

  // const onTermsAndConditionsClick = () => {
  //   routeService.push({
  //     path: ROUTE_NAMES.HomeTermsAndConditions
  //   });
  // };

  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.phoneContainer}>
        <TwBfForm form={form} elements={elements} />
      </View>

      {/* <View style={styles.confirmContainer}>
        <TwCheckbox checked={isPolicyAgreed} onChange={onAgreeCheck} />
        <Text style={styles.confirmText}>我已阅读同意</Text>
        <TouchableOpacity onPress={onPrivacyPolicyClick}>
          <Text style={styles.confirmLink}>隐私政策</Text>
        </TouchableOpacity>
        <Text style={styles.confirmTextWithNoMargin}>和</Text>
        <TouchableOpacity onPress={onTermsAndConditionsClick}>
          <Text style={styles.confirmLink}>用户协议</Text>
        </TouchableOpacity>
      </View> */}

      <View style={styles.loginButtonContainer}>
        <TwButton style={styles.loginButton} testID={'LoginBtn'} onPress={onLoginClick} isLoading={false}>
          登录
        </TwButton>
      </View>
    </View>
  );
};

function useElements(): BfFormElement[] {
  return useMemo(
    () =>
      [
        {
          id: 'phone',
          name: '手机号码',
          type: 'phone',
          widgetOptions: {
            phoneType: 'mobile'
          },
          validations: [
            {
              //
            }
          ]
        },
        {
          id: 'captcha',
          name: '验证码',
          widget: 'VerifyCode',
          type: 'text',
          widgetOptions: {
            phoneType: 'text',
            codeLength: 4,
            userType: {
              code: UserTypeEnumValue.company
            },
            usage: {
              code: CaptchaUsageEnumValue.Verify
            },
            identifierEmptyError: '请输入手机号'
          },
          validations: [
            {
              //
            }
          ],
          reactions: [
            {
              dependencies: {
                phone: '.phone'
              },
              fulfill: {
                schema: {
                  'x-component-props': {
                    identifier: '{{$deps.phone}}'
                  }
                }
              }
            }
          ]
        }
      ] as BfFormElement[],
    []
  );
}

interface FormData {
  phone: string;
}
