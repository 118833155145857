import { enableExperimentalWebImplementation } from 'react-native-gesture-handler';
import { registerRootComponent } from 'expo';
import '@/app/core/setup';
import App from '@/app/App';
import React from 'react';
import { BfErrorHandler } from '@/app/error-handler';
import { BfAppErrorAlert } from '@/app/error-alert/error-alert';
enableExperimentalWebImplementation();
registerRootComponent(App);
console.log('==version: 0704==');
BfErrorHandler.setJsErrorHandler((error, isFatal) => {
  if (!isFatal) {
    return;
  }
  BfAppErrorAlert.getInstance().showError({
    isNative: false,
    message: error.message,
    error: error
  });
});
